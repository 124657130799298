// ** Reactstrap Imports
import { Card, CardHeader, Progress } from 'reactstrap'

// ** Third Party Components
import { ChevronDown, UserMinus } from 'react-feather'
import DataTable from 'react-data-table-component'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'
import { useDispatch, useSelector } from 'react-redux'
import { unassignProject } from '../store'
import { useParams } from 'react-router-dom'
import { getUserData } from '../../../../auth/utils'
import { useEffect, useMemo, useState } from 'react'


const MySwal = withReactContent(Swal)

const UserProjectsList = () => {
  const { projectUsers } = useSelector(state => state.projects)
  console.debug("🚀 ~ file: UserProjectsList.js:131 ~ UserProjectsList ~ store", projectUsers)

  const [isAdmin, setIsAdmin] = useState(false);

  // currently loggedin user
  const user = getUserData()?.token.data;

  useEffect(() => {
    if (user?.rolename === "Admin") {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }
  }, [user])

  // ** Hooks
  const { id } = useParams()

  const dispatch = useDispatch();

  const handleUnAssign = (row) => {
    console.debug("🚀 ~ file: UserProjectsList.js:83 ~ handleUnAssign ~ row", row)
    const { AssignProjectID } = row;

    return MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert un-assign!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Un-assigned user!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false
    }).then(function (result) {
      if (result.value) {
        //** Un-assign user from project  **//
        dispatch(unassignProject({ asspid: AssignProjectID, pid: id }));
        //** firing deleted popup  **//
        MySwal.fire({
          icon: 'success',
          title: 'Suspended!',
          text: 'User has been suspended.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: 'Cancelled',
          text: 'Cancelled Suspension',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    })

  }

  const columns = useMemo(() => {
    const tempColumns = [
      {
        sortable: true,
        minWidth: '300px',
        name: 'Project',
        selector: row => row,
        cell: row => {
          return (
            <div className='d-flex justify-content-left align-items-center'>
              <div className='avatar-wrapper'>
                <Avatar className='me-1' content={!!row.UserFullName ? row.UserFullName.substring(0, 1) : 'Project Name'} color='light-primary' alt={row.UserFullName} imgWidth='32' />
              </div>
              <div className='d-flex flex-column'>
                <span className='text-truncate fw-bolder'>{row.UserFullName}</span>
                <small className='text-muted'>{row.UserEmail}</small>
              </div>
            </div>
          )
        }
      },
      {
        name: 'Project Creator',
        selector: row => row.createrName
      },
    ]

    const actionCol = {
      name: 'Action',
      selector: row => row,
      cell: row => (
        <div className='d-flex gap-2'>
          <UserMinus className='font-medium-3 text-danger cursor-pointer' onClick={() => handleUnAssign(row)} />
        </div>
      )
    }

    if (isAdmin) {
      tempColumns.push(actionCol)
      return tempColumns
    } else {
      return tempColumns
    }
  })

  return (
    <Card>
      <CardHeader tag='h4'>Project's Users</CardHeader>
      <div className='react-dataTable user-view-account-projects'>
        <DataTable
          noHeader
          responsive
          columns={columns}
          data={projectUsers}
          className='react-dataTable'
          sortIcon={<ChevronDown size={10} />}
        />
      </div>
    </Card>
  )
}

export default UserProjectsList
