// ** Icons Import
import { Map as MapIcon, Settings } from 'react-feather'

export default [
    {
        header: 'Pages'
    },
    {
        id: 'tours',
        title: 'Tours',
        icon: <MapIcon size={20} />,
        navLink: '/tours'
    },
    {
        id: 'account-settings',
        title: 'Settings',
        icon: <Settings size={20} />,
        navLink: '/account-settings'
    },
]