// ** Icons Import
import { CheckSquare, Users, Map as MapIcon, Settings } from 'react-feather'
export default [
  {
    header: 'Pages'
  },
  {
    id: 'users',
    title: 'Users',
    icon: <Users size={20} />,
    navLink: '/users'
  },
  {
    id: 'tours',
    title: 'Tours',
    icon: <MapIcon size={20} />,
    navLink: '/tours'
  },
  {
    id: 'logs',
    title: 'Logs',
    icon: <CheckSquare size={20} />,
    navLink: '/logs'
  },
  {
    id: 'account-settings',
    title: 'Settings',
    icon: <Settings size={20} />,
    navLink: '/account-settings'
  },
]