
const Footer = () => {
  return (
    <p className='clearfix mb-0'>
      <span className='float-md-start d-block d-md-inline-block mt-25'>
        COPYRIGHT © {new Date().getFullYear()}{' '}
        <a href='https://itisticsolutions.com/' target='_blank' rel='noopener noreferrer'>
          Virtual Tour CMS
        </a>
        <span className='d-none d-sm-inline-block'>, All rights Reserved</span>
      </span>
      {/* <span className='float-md-end d-none d-md-block'>
        Design & Developed By <a href="https://itisticsolutions.com/">ITistic Solutions PVT LTD</a>
      </span> */}
    </p>
  )
}

export default Footer
