// ** React Imports
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
import { useDispatch } from 'react-redux'
import { saveData } from '../redux/authentication'
import { getUserData } from '../auth/utils'
import AuthAdmin from '../navigation/vertical'

const VerticalLayout = props => {
  const dispatch = useDispatch();
  dispatch(saveData())
  const user = getUserData();
  return (
    <Layout menuData={AuthAdmin(user?.role)} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
