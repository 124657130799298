// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

let headerToken = 'PR3Qv3deUgF-w1A8AUvhpkQhZiBimZHqrR2-PA82EvKT9qjmW1qkL5YZZUc8kkHQ'
let siteId = 'vt-cms-luqc.vercel.app'
let AnalyticsAPI = 'https://plausible.io/api/v1/'

export const getAnalyticsGraph = createAsyncThunk("dashboard/analytics", async (body) => {
  console.debug("🚀 ~ file: index.js:12 ~ getAnalyticsGraph ~ body:", body)
  const res = await axios.get(`${AnalyticsAPI}/stats/timeseries?site_id=${siteId}&period=${body.type}&filters=event:page==${body.name}`, {
    headers: {
      authorization: `Bearer ${headerToken}`
    }
  });
  return res.data;
});
export const getAllAnalytics = createAsyncThunk("dashboard/allanalytics", async (body, { dispatch }) => {
  const res = await axios.get(`${AnalyticsAPI}/stats/aggregate?site_id=${siteId}&metrics=visitors,pageviews,bounce_rate,visit_duration&period=${body.type}&filters=event:page==${body.name}`, {
    headers: {
      authorization: `Bearer ${headerToken}`
    }
  });
  await dispatch(getRealtime())
  return res.data;
});

export const getRealtime = createAsyncThunk("dashboard/realtime", async () => {
  const res = await axios.get(`${AnalyticsAPI}/stats/realtime/visitors?site_id=${siteId}`, {
    headers: {
      authorization: `Bearer ${headerToken}`
    }
  });
  return res.data;
});
export const getSources = createAsyncThunk("dashboard/sources", async (body) => {
  const res = await axios.get(`${AnalyticsAPI}/stats/breakdown?site_id=${siteId}&property=visit:source&metrics=visitors&period=${body.type}&filters=event:page==${body.name}`, {
    headers: {
      authorization: `Bearer ${headerToken}`
    }
  });
  return res.data;
});
export const getPages = createAsyncThunk("dashboard/pages", async (body) => {
  const res = await axios.get(`${AnalyticsAPI}/stats/breakdown?site_id=${siteId}&property=event:page&period=${body.type}&filters=event:page==${body.name}`, {
    headers: {
      authorization: `Bearer ${headerToken}`
    }
  });
  return res.data;
});

export const getAnalyticsGraphByUsers = createAsyncThunk("dashboard/analytics", async (body) => {
  console.debug("🚀 ~ file: index.js:12 ~ getAnalyticsGraph ~ body", body)
  const res = await axios.get(`${AnalyticsAPI}/stats/timeseries?site_id=${siteId}&period=${body.type}&filters=event:page==${body.name}`, {
    headers: {
      authorization: `Bearer ${headerToken}`
    }
  });
  return res.data;
});
export const getAllAnalyticsByUsers = createAsyncThunk("dashboard/allanalytics", async (body, { dispatch }) => {
  const res = await axios.get(`${AnalyticsAPI}/stats/aggregate?site_id=${siteId}&metrics=visitors,pageviews,bounce_rate,visit_duration&period=${body.type}&filters=event:page==${body.name}`, {
    headers: {
      authorization: `Bearer ${headerToken}`
    }
  });
  await dispatch(getRealtimeByUsers(body.name))
  return res.data;
});

export const getRealtimeByUsers = createAsyncThunk("dashboard/realtime", async (body) => {
  const res = await axios.get(`${AnalyticsAPI}/stats/realtime/visitors?site_id=${siteId}&filters=event:page==${body}`, {
    headers: {
      authorization: `Bearer ${headerToken}`
    }
  });
  return res.data;
});
export const getSourcesByUsers = createAsyncThunk("dashboard/sources", async (body) => {
  console.debug("🚀 ~ file: index.js:82 ~ getSourcesByUsers ~ body:", body)
  const res = await axios.get(`${AnalyticsAPI}/stats/breakdown?site_id=${siteId}&property=visit:source&metrics=visitors&filters=event:page==${body.name}`, {
    headers: {
      authorization: `Bearer ${headerToken}`
    }
  });
  return res.data;
});
export const getPagesByUsers = createAsyncThunk("dashboard/pages", async (body) => {
  const res = await axios.get(`${AnalyticsAPI}/stats/breakdown?site_id=${siteId}&property=event:page&filters=event:page==${body.name}`, {
    headers: {
      authorization: `Bearer ${headerToken}`
    }
  });
  return res.data;
});


export const appLogsSlice = createSlice({
  name: 'analytics',
  initialState: {
    data: [],
    allData: [],
    realTime: '',
    sources: [],
    pages: [],
    tour: []
  },
  reducers: {},
  extraReducers: {
    [getAnalyticsGraph.pending]: (state) => {
      state.loading = true;
    },
    [getAnalyticsGraph.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getAnalyticsGraph.rejected]: (state) => {
      state.loading = false;
    },
    [getAllAnalytics.pending]: (state) => {
      state.loading = true;
    },
    [getAllAnalytics.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.allData = payload;
    },
    [getAllAnalytics.rejected]: (state) => {
      state.loading = false;
    },
    [getRealtime.pending]: (state) => {
      state.loading = true;
    },
    [getRealtime.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.realTime = payload;
    },
    [getRealtime.rejected]: (state) => {
      state.loading = false;
    },
    [getSources.pending]: (state) => {
      state.loading = true;
    },
    [getSources.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sources = payload;
    },
    [getSources.rejected]: (state) => {
      state.loading = false;
    },
    [getPages.pending]: (state) => {
      state.loading = true;
    },
    [getPages.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.pages = payload;
    },
    [getPages.rejected]: (state) => {
      state.loading = false;
    },
  }
})

export default appLogsSlice.reducer
