// ** React Imports
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { handleLogout } from '@store/authentication'

// ** Third Party Components
import { Settings, Power } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const { userData: user } = useSelector(state => state.auth)

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name fw-bold lh-lg'>{user && user?.fullName}</span>
          <span className='user-status'>{(user && user.role) || 'Admin'}</span>
        </div>
        <Avatar color={'light-primary'} content={user && user?.fullName?.substring(0, 1) || "A"} status='online' />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to='/account-settings'>
          <Settings size={14} className='me-75' />
          <span className='align-middle'>Account Settings</span>
        </DropdownItem>
        {/* <DropdownItem tag={Link} to='/chat'>
          <MessageSquare size={14} className='me-75' />
          <span className='align-middle'>Chats</span>
        </DropdownItem> */}
        <DropdownItem tag={Link} to='/login' onClick={() => {
          dispatch(handleLogout());
        }}>
          <Power size={14} className='me-75' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
