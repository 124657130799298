// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API URL Imports
import { API_URL } from "@src/utility/Utils";

// ** Axios Imports
import axios from 'axios'

// ** Avatar Imports
import Avatar from '@components/avatar'
import { CheckCircle, X, XCircle } from 'react-feather';
import toast from 'react-hot-toast';


const ToastContent = ({ t, success }) => {
  return (
    <div className='d-flex'>
      <div className='me-1'>
        <Avatar size='sm' color={success ? 'success' : 'danger'} icon={success ? <CheckCircle size={12} /> : <XCircle size={12} />} />
      </div>
      <div className='d-flex justify-content-between align-items-center gap-1'>
        <span>{success ? 'Details added.' : 'Something went wrong.'}</span>
        <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)} />
      </div>
    </div>
  )
}

// get all project
export const getAllTours = createAsyncThunk("tour/getAllTours", async () => {
  const res = await axios.get(`${API_URL}tour/`);
  return res.data.Tours;
});

// get all project
export const tourByUser = createAsyncThunk("tour/tourByUser", async (id) => {
  const res = await axios.get(`${API_URL}tour/user/${id}/`);
  return res.data;
});

// get tour by id
export const getTour = createAsyncThunk("tour/getTour", async (id) => {
  const res = await axios.get(`${API_URL}tour/${id}/`);
  return res.data;
});

// get hotspots by tour id
export const getTourHotspots = createAsyncThunk("tour/getTourHotspots", async (id) => {
  const res = await axios.get(`${API_URL}hotspot/tour/${id}/`);
  return res.data;
});

// get tour by tour name
export const getTourByName = createAsyncThunk("tour/getTourByName", async (name) => {
  const res = await axios.get(`${API_URL}tour/name/${name}/`);
  return res.data[0];
});

// add project
export const addTour = createAsyncThunk(
  "tour/addTour",
  async (body, { dispatch }) => {
    const res = await axios.post(`${API_URL}tour/`, body);
    await dispatch(getAllTours())
    return res.data.Tours;
  }
);

// add hotspot details
export const addHotspotData = createAsyncThunk("tour/addHotspotData", async (body) => {
  const res = await axios.post(`${API_URL}hotspot/all/`, body);
  return res.data;
});

// add hotspot details
export const uploadTourImage = createAsyncThunk("tour/uploadTourImage", async (body) => {
  const res = await axios.post(`${API_URL}tour/image/`, body);
  return res.data;
});

// get hotspot by id
export const getHotspot = createAsyncThunk("tour/getHotspot", async (id) => {
  const res = await axios.get(`${API_URL}hotspot/${id}/`);
  return res.data;
});

// update tour
export const updateTour = createAsyncThunk(
  "tour/updateTour",
  async ({ id, body }, { dispatch }) => {
    const res = await axios.put(`${API_URL}tour/${id}/`, body);
    await dispatch(getTour(id))
    return res.data.tour;
  }
);

// update hotspot
export const updateHotspot = createAsyncThunk(
  "tour/updateHotspot",
  async ({ id, body }, { dispatch }) => {
    const res = await axios.put(`${API_URL}hotspot/${id}/`, body);
    await dispatch(getHotspot(id))
    return res.data.hotspot;
  }
);
export const updateHotspotGallery = createAsyncThunk(
  "tour/updateHotspotGallery",
  async (body) => {
    const res = await axios.post(`${API_URL}hotspot/hostpotGalleryImage/`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data
  }
);
export const updateHotspotLogo = createAsyncThunk(
  "tour/updateHotspotLogo",
  async (body) => {
    const res = await axios.post(`${API_URL}hotspot/hostpotImage/`, body, {
    });
    return res.data
  }
);


// remove project
export const removeProject = createAsyncThunk(
  "users/removeProject",
  async (pid, { dispatch }) => {
    const res = await axios.post(`${API_URL}project/delete.php`, { pid });
    await dispatch(getAllProjects())
    return res.data.body;
  }
);

// un-assign user from project
export const unassignProject = createAsyncThunk(
  "users/unassignProject",
  async (body, { dispatch }) => {
    const res = await axios.post(`${API_URL}assignproject/delete.php`, { asspid: body.asspid });
    await dispatch(getProjectUsers(body.pid))
    return res.data.body;
  }
);

// assign project
export const assignProject = createAsyncThunk(
  "project/assignProject",
  async (body, { dispatch }) => {
    const res = await axios.post(`${API_URL}assignproject/create.php`, body);
    await dispatch(getProjectUsers(body.pid))
    return res.data.body;
  }
);

// get project users by user_id
export const getProjectUsers = createAsyncThunk(
  "project/getProjectUsers",
  async (pid) => {
    const res = await axios.get(`${API_URL}assignproject/projectusers.php?pid=${pid}`);
    return res.data;
  }
);

// get projects along with users
export const getProjectsWithCreator = createAsyncThunk(
  "project/getProjectsWithCreator",
  async (pid) => {
    const res = await axios.get(`${API_URL}project/projectWithCreator.php?pid=${pid}`);
    return res.data;
  }
);

// add newsletter
export const addSubscriberData = createAsyncThunk("tour/newsletter", async ({ data, id }) => {
  const res = await axios.put(`${API_URL}tour/addSubscriber/${id}/`, { subscribers: data });
  return res.data;
});

export const tourSlice = createSlice({
  name: 'tours',
  initialState: {
    loading: false,
    vtLoading: false,
    hotspotLoading: false,
    getHotspotLoading: false,
    tours: [],
    userProjects: [],
    currentTour: null,
    currentHotspot: null,
    editProject: null,
    postTourRes: null,
    postHotspotRes: null,
    postTourImageRes: null,
    updateTourRes: null,
    updateHotspotRes: null,
    assignProjectRes: null,
    projectsWithCreator: null,
    projectUsers: null,
    hotspots: null,
  },
  reducers: {
    setEditProject: (state, action) => {
      state.editProject = action.payload;
    },
  },
  extraReducers: {
    // get projects
    [getAllTours.pending]: (state) => {
      state.loading = true;
    },
    [getAllTours.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tours = payload;
    },
    [getAllTours.rejected]: (state) => {
      state.loading = false;
    },

    // get projects by users
    [tourByUser.pending]: (state) => {
      state.loading = true;
    },
    [tourByUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tours = payload;
    },
    [tourByUser.rejected]: (state) => {
      state.loading = false;
    },

    // get projects
    [getTour.pending]: (state) => {
      state.loading = true;
    },
    [getTour.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.currentTour = payload;
    },
    [getTour.rejected]: (state) => {
      state.loading = false;
    },

    // get hotspot
    [getHotspot.pending]: (state) => {
      state.getHotspotLoading = true;
    },
    [getHotspot.fulfilled]: (state, { payload }) => {
      state.getHotspotLoading = false;
      state.currentHotspot = payload;
    },
    [getHotspot.rejected]: (state) => {
      state.getHotspotLoading = false;
    },

    // get Hotspots
    [getTourHotspots.pending]: (state) => {
      state.hotspotLoading = true;
    },
    [getTourHotspots.fulfilled]: (state, { payload }) => {
      state.hotspotLoading = false;
      state.hotspots = payload;
    },
    [getTourHotspots.rejected]: (state) => {
      state.hotspotLoading = false;
    },

    // get Tour by name
    [getTourByName.pending]: (state) => {
      state.vtLoading = true;
    },
    [getTourByName.fulfilled]: (state, { payload }) => {
      state.vtLoading = false;
      state.currentTour = payload;
    },
    [getTourByName.rejected]: (state) => {
      state.vtLoading = false;
    },

    // get projects along with users
    [getProjectsWithCreator.pending]: (state) => {
      state.loading = true;
    },
    [getProjectsWithCreator.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projectsWithCreator = payload;
    },
    [getProjectsWithCreator.rejected]: (state) => {
      state.loading = false;
    },

    // post tour
    [addTour.pending]: (state) => {
      state.loading = true;
    },
    [addTour.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postTourRes = payload;
    },
    [addTour.rejected]: (state) => {
      state.loading = false;
    },

    // post hotspot
    [addHotspotData.pending]: (state) => {
      state.loading = true;
    },
    [addHotspotData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postHotspotRes = payload;
      // Show success toast after data post
      // toast(t => <ToastContent success={true} t={t} />);
    },
    [addHotspotData.rejected]: (state) => {
      state.loading = false;
      // Show error toast after data post
      // toast(t => <ToastContent success={false} t={t} />);
    },

    // post image
    [uploadTourImage.pending]: (state) => {
      state.loading = true;
    },
    [uploadTourImage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postTourImageRes = payload;
    },
    [uploadTourImage.rejected]: (state) => {
      state.loading = false;
    },

    // remove project
    [removeProject.pending]: (state) => {
      state.loading = true;
    },
    [removeProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postProjectRes = payload;
    },
    [removeProject.rejected]: (state) => {
      state.loading = false;
    },

    // remove project
    [unassignProject.pending]: (state) => {
      state.loading = true;
    },
    [unassignProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postProjectRes = payload;
    },
    [unassignProject.rejected]: (state) => {
      state.loading = false;
    },

    // update tour
    [updateTour.pending]: (state) => {
      state.loading = true;
    },
    [updateTour.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateTourRes = payload;
      // Show success toast after data post
      toast(t => <ToastContent success={true} t={t} />);
    },
    [updateTour.rejected]: (state) => {
      state.loading = false;
      // Show error toast after data post
      toast(t => <ToastContent success={false} t={t} />);
    },

    // update hotspot
    [updateHotspot.pending]: (state) => {
      state.hotspotLoading = true;
    },
    [updateHotspot.fulfilled]: (state, { payload }) => {
      state.hotspotLoading = false;
      state.updateHotspotRes = payload;
      // Show success toast after data post
      toast(t => <ToastContent success={true} t={t} />);
    },
    [updateHotspot.rejected]: (state) => {
      state.hotspotLoading = false;
      // Show error toast after data post
      toast(t => <ToastContent success={false} t={t} />);
    },

    // assign project
    [assignProject.pending]: (state) => {
      state.loading = true;
    },
    [assignProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.assignProjectRes = payload;
    },
    [assignProject.rejected]: (state) => {
      state.loading = false;
    },

    // assign project
    [getProjectUsers.pending]: (state) => {
      state.loading = true;
    },
    [getProjectUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projectUsers = payload;
    },
    [getProjectUsers.rejected]: (state) => {
      state.loading = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setEditUser } = tourSlice.actions;
export default tourSlice.reducer
