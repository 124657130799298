// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API URL Imports
import { API_URL } from "@src/utility/Utils";

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast';

// get all users
export const getAllUsers = createAsyncThunk("users/getAllUsers", async () => {
  const res = await axios.get(`${API_URL}user/`);
  return res.data;
});

// get all users with their role
export const getUsersWithRole = createAsyncThunk(
  "users/getUsersWithRole",
  async () => {
    const res = await axios.get(`${API_URL}Role/role_join.php`);
    return res.data.body;
  }
);

export const getAllRoles = createAsyncThunk(
  "users/getAllRoles",
  async () => {
    const res = await axios.get(`${API_URL}Role/read.php`);
    return res.data.body;
  }
);

// add user
export const addUser = createAsyncThunk("user/create", async (body, { dispatch }) => {
  console.debug("🚀 ~ file: index.js:36 ~ addUser ~ body", body)
  const res = await axios.post(`${API_URL}user/create/`, body);
  await dispatch(getAllUsers())
  return res.data;
});

// remove user
export const removeUser = createAsyncThunk("user/removeUser", async (body, { dispatch }) => {
  const res = await axios.delete(`${API_URL}user/${body.id}/`, body.currUserId);
  await dispatch(getAllUsers())
  return res.data;
});

// update user
export const updateUser = createAsyncThunk("users/updateUser", async (body, { dispatch }) => {
  const res = await axios.put(`${API_URL}user/${body.id}/`, body);
  await dispatch(getAllUsers())
  return res.data;
});

// update user
export const editProfile = createAsyncThunk("users/editProfile", async (body, { dispatch }) => {
  const res = await axios.put(`${API_URL}user/${body.id}/`, body);
  return res.data;
});

export const changePassword = createAsyncThunk("users/password", async (body, { dispatch }) => {
  const res = await axios.put(`${API_URL}user/password/${body.id}/`, body);
  return res.data;
});

export const usersSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    usersWithRole: [],
    roles: [],
    loading: false,
    editUser: null,
    postUserRes: "",
    updateUserRes: "",
    editProfileRes: "",
  },
  reducers: {},
  extraReducers: {
    // get users
    [getAllUsers.pending]: (state) => {
      state.loading = true;
    },
    [getAllUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.users = payload;
    },
    [getAllUsers.rejected]: (state) => {
      state.loading = false;
    },
    [changePassword.pending]: (state) => {
      state.loading = true;
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.users = payload;
      toast.success(payload.message, {
        style: {
          padding: '16px',
          color: "#59B259",
          border: `1px solid #59B259`
        },
        iconTheme: {
          primary: "#59B259"
        }
      })
    },
    [changePassword.rejected]: (state) => {
      state.loading = false;
      toast.error('Invalid Current Password', {
        style: {
          padding: '16px',
          color: "#C9000F",
          border: `1px solid #C9000F`
        },
        iconTheme: {
          primary: "#C9000F"
        }
      })
    },

    [getAllRoles.pending]: (state) => {
      state.loading = true;
    },
    [getAllRoles.fulfilled]: (state, { payload }) => {
      state.roles = payload;
      state.loading = false;
    },
    [getAllRoles.rejected]: (state) => {
      state.loading = false;
    },


    // get users with role
    [getUsersWithRole.pending]: (state) => {
      state.loading = true;
    },
    [getUsersWithRole.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.usersWithRole = payload;
    },
    [getUsersWithRole.rejected]: (state) => {
      state.loading = false;
    },

    // post user
    [addUser.pending]: (state) => {
      state.loading = true;
    },
    [addUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postUserRes = payload;
      if (payload.status === 201) {
        toast.success(payload.message, {
          style: {
            padding: '16px',
            color: "#59B259",
            border: `1px solid #59B259`
          },
          iconTheme: {
            primary: "#59B259"
          }
        })
      } else {
        toast.error(payload.message, {
          style: {
            padding: '16px',
            color: "#C9000F",
            border: `1px solid #C9000F`
          },
          iconTheme: {
            primary: "#C9000F"
          }
        })
      }
    },
    [addUser.rejected]: (state) => {
      state.loading = false;
      toast.error("Something went wrong", {
        style: {
          padding: '16px',
          color: "#C9000F",
          border: `1px solid #C9000F`
        },
        iconTheme: {
          primary: "#C9000F"
        }
      })
    },

    // remove user
    [removeUser.pending]: (state) => {
      state.loading = true;
    },
    [removeUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postUserRes = payload;
    },
    [removeUser.rejected]: (state) => {
      state.loading = false;
    },

    // update user
    [updateUser.pending]: (state) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateUserRes = payload;
    },
    [updateUser.rejected]: (state) => {
      state.loading = false;
    },

    // edit profile
    [editProfile.pending]: (state) => {
      state.loading = true;
    },
    [editProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.editProfileRes = payload;
    },
    [editProfile.rejected]: (state) => {
      state.loading = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setEditUser } = usersSlice.actions;
export default usersSlice.reducer
