// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'


const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : null
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    loading: false,
    login: null,
    data: []
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      localStorage.setItem('userData', JSON.stringify(action.payload))
    },
    handleLogout: state => {
      state.userData = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
    },
    saveData: (state, action) => {
      let User = JSON.parse(localStorage.getItem('userData'));
      state.data = User
    },
  },
})

export const { handleLogin, handleLogout, saveData } = authSlice.actions

export default authSlice.reducer
