// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { API_URL } from "@src/utility/Utils";


export const getAllLogs = createAsyncThunk("logs/getAllLogs", async () => {
  const res = await axios.get(`${API_URL}logs/`);
  return res.data;
});

export const appLogsSlice = createSlice({
  name: 'logs',
  initialState: {
    tasks: [],
  },
  reducers: {},
  extraReducers: {
    [getAllLogs.pending]: (state) => {
      state.loading = true;
    },
    [getAllLogs.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tasks = payload.data.reverse();
    },
    [getAllLogs.rejected]: (state) => {
      state.loading = false;
    },
  }
})

export default appLogsSlice.reducer
