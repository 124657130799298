// ** React Imports
import { useState, Fragment, useEffect } from 'react'

// ** Reactstrap Imports
import { Row, Col, Card, Form, CardBody, Button, Badge, Modal, Input, Label, ModalBody, ModalHeader, Spinner } from 'reactstrap'

// ** Third Party Components
import Swal from 'sweetalert2'
import Select, { components } from 'react-select'
import { Calendar } from 'react-feather'
import withReactContent from 'sweetalert2-react-content'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import { selectThemeColors } from '@utils'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getUsersWithRole } from '../../users/store'
import { assignProject, removeProject } from '../store'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserData } from '../../../../auth/utils'

const roleColors = {
    editor: 'light-info',
    admin: 'light-danger',
    author: 'light-warning',
    maintainer: 'light-success',
    subscriber: 'light-primary'
}

const statusColors = {
    active: 'light-success',
    pending: 'light-warning',
    inactive: 'light-secondary'
}

const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'suspended', label: 'Suspended' }
]

const countryOptions = [
    { value: 'uk', label: 'UK' },
    { value: 'usa', label: 'USA' },
    { value: 'france', label: 'France' },
    { value: 'russia', label: 'Russia' },
    { value: 'canada', label: 'Canada' }
]

const languageOptions = [
    { value: 'english', label: 'English' },
    { value: 'spanish', label: 'Spanish' },
    { value: 'french', label: 'French' },
    { value: 'german', label: 'German' },
    { value: 'dutch', label: 'Dutch' }
]

const MySwal = withReactContent(Swal)

let data = {
    username: 'MoidKhan',
    fullName: 'Moid Khan',
    role: 'Admin',
    email: 'moidkhan@gmail.com',
    status: 'pending',
    contact: 'Karachi'
}

const AssigneeComponent = ({ data, ...props }) => {
    return (
        <components.Option {...props}>
            <div className='d-flex align-items-center'>
                <Avatar initials className='me-1' color={'light-primary'} content={data.label} />
                <p className='mb-0'>{data.label}{' | '}<small>{data.role}</small></p>
            </div>
        </components.Option>
    )
}

const UserInfoCard = ({
    username = 'MoidKhan',
    fullName = 'Moid Khan',
    role = 'Admin',
    email = 'moidkhan@gmail.com',
    status = 'pending',
    contact = 'Karachi'
}) => {
    // ** State
    const [show, setShow] = useState(false)
    const [assignedTo, setAssignedTo] = useState(null)
    const [assigneeOptions, setAssigneeOptions] = useState([])

    // ** Hooks
    const { id } = useParams()
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { usersWithRole: users } = useSelector(state => state.user)
    const { currentProject, loading, projectsWithCreator } = useSelector(state => state.projects)
    console.debug("🚀 ~ file: UserInfoCard.js:104 ~ projectsWithCreator", projectsWithCreator)

    const [isAdmin, setIsAdmin] = useState(false);

    // currently loggedin user
    const user = getUserData()?.token.data;

    useEffect(() => {
        if (user?.rolename === "Admin") {
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }, [user])

    useEffect(() => {
        dispatch(getUsersWithRole())
    }, [])

    useEffect(() => {
        // ** Assignee Select Options
        if (users) {
            const data = [];
            users.map((user) => {
                data.push({
                    value: user.uid,
                    label: user.ufullname,
                    role: user.name,
                    roleid: user.roleid,
                    uid: user.uid,
                    pid: id
                })
            })
            setAssigneeOptions(data)
        }
    }, [users])

    // ** render user img
    const renderUserImg = () => {
        return (
            <Avatar
                initials
                color={!!currentProject ? currentProject?.pname : 'light-primary'}
                className='rounded mt-3 mb-2'
                content={!!currentProject ? currentProject?.pname : 'Project Name'}
                contentStyles={{
                    borderRadius: 0,
                    fontSize: 'calc(48px)',
                    width: '100%',
                    height: '100%'
                }}
                style={{
                    height: '110px',
                    width: '110px'
                }}
            />
        )
    }

    const handleSuspendedClick = () => {
        return MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert user!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Suspend user!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false
        }).then(function (result) {
            if (result.value) {
                //** deleting project  **//
                dispatch(removeProject(id))
                //** firing deleted popup  **//
                MySwal.fire({
                    icon: 'success',
                    title: 'Suspended!',
                    text: 'User has been suspended.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
                //** redirecting user back to projects page  **//
                navigate('/projects');
            } else if (result.dismiss === MySwal.DismissReason.cancel) {
                MySwal.fire({
                    title: 'Cancelled',
                    text: 'Cancelled Suspension',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            }
        })
    }

    const handleAssignProject = () => {
        if (!assignedTo) return;

        let promises = [];
        assignedTo.map((project) => {
            promises.push(
                dispatch(assignProject({
                    assignerid: '6',
                    assignerroleid: '2',
                    roleid: project.roleid,
                    uid: project.uid,
                    pid: project.pid
                }))
            )
        })

        // Resolving all api's responsie
        Promise.all(promises).then(() => {
            console.debug('Project assigned succesfully')
            // closing the modal
            setShow(false)
        });
    }

    if (!users) return <p>Loading...</p>

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className='user-avatar-section'>
                        <div className='d-flex align-items-center flex-column'>
                            {renderUserImg()}
                            <div className='d-flex flex-column align-items-center text-center'>
                                <div className='user-info'>
                                    <h4>{!!currentProject ? currentProject?.pname : 'Project Name'}</h4>
                                    {role ? (
                                        <Badge color={roleColors[role]} className='text-capitalize'>
                                            {!!currentProject ? currentProject.status : 'In Progress'}
                                        </Badge>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-around my-2 pt-75'>
                        <div className='d-flex align-items-center me-2'>
                            <Badge color='light-primary' className='rounded p-75'>
                                <Calendar className='font-medium-2' />
                            </Badge>
                            <div className='ms-75'>
                                <h6 className='mb-0'>{!!currentProject ? currentProject.pstartdate : new Date().toLocaleDateString()}</h6>
                                <small>Start Date</small>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Badge color='light-primary' className='rounded p-75'>
                                <Calendar className='font-medium-2' />
                            </Badge>
                            <div className='ms-75'>
                                <h6 className='mb-0'>{!!currentProject ? currentProject.penddate : new Date().toLocaleDateString()}</h6>
                                <small>End Date</small>
                            </div>
                        </div>
                    </div>
                    <h4 className='fw-bolder border-bottom pb-50 mb-1'>Details</h4>
                    <div className='info-container'>
                        {!!projectsWithCreator ? (
                            <ul className='list-unstyled'>
                                <li className='mb-75'>
                                    <span className='fw-bolder me-25'>Description:</span>
                                    <span>{projectsWithCreator?.pdes}</span>
                                </li>
                                <li className='mb-75'>
                                    <span className='fw-bolder me-25'>Status:</span>
                                    <Badge className='text-capitalize' color={statusColors[status]}>
                                        {projectsWithCreator?.status}
                                    </Badge>
                                </li>
                                {isAdmin && <>
                                    <li className='mb-75'>
                                        <span className='fw-bolder me-25'>Creator Email:</span>
                                        <span>{projectsWithCreator?.UserEmail}</span>
                                    </li>
                                    <li className='mb-75'>
                                        <span className='fw-bolder me-25'>Creator Role:</span>
                                        <span className='text-capitalize'>{projectsWithCreator?.UserRoleName}</span>
                                    </li>
                                </>}
                            </ul>
                        ) : null}
                    </div>
                    {isAdmin && <div className='d-flex justify-content-center pt-2'>
                        <Button color='primary' onClick={() => setShow(true)}>
                            Assign
                        </Button>
                        <Button className='ms-1' color='danger' outline onClick={handleSuspendedClick}>
                            Delete
                        </Button>
                    </div>}
                </CardBody>
            </Card>
            <Modal isOpen={show} toggle={() => setShow(!show)} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>
                <ModalBody className='px-sm-5 pt-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Add Users</h1>
                        <p>Assign users to this project</p>
                    </div>
                    <div>
                        <div className='mb-1'>
                            <Label className='form-label' for='task-assignee'>
                                Assignee
                            </Label>
                            <Select
                                isMulti
                                id='task-assignee'
                                value={assignedTo}
                                isClearable={false}
                                className='react-select'
                                classNamePrefix='select'
                                options={assigneeOptions}
                                theme={selectThemeColors}
                                onChange={data => setAssignedTo(data)}
                                components={{ Option: AssigneeComponent }}
                            />
                        </div>
                        <Col xs={12} className='text-center mt-2 pt-50'>
                            <Button className='me-1' onClick={handleAssignProject} color='primary'>
                                {loading ? <Spinner color='light' /> : 'Assign'}
                            </Button>
                            <Button
                                disabled={loading}
                                color='secondary'
                                outline
                                onClick={() => {
                                    setShow(false)
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default UserInfoCard
