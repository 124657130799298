// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ProjectView from '../../views/apps/projects/view'

// const Chat = lazy(() => import('../../views/apps/chat'))
const Todo = lazy(() => import('../../views/apps/todo'))
const Logs = lazy(() => import('../../views/apps/logs'))
const Email = lazy(() => import('../../views/apps/email'))
const Kanban = lazy(() => import('../../views/apps/kanban'))
const Calendar = lazy(() => import('../../views/apps/calendar'))

const InvoiceAdd = lazy(() => import('../../views/apps/invoice/add'))
const InvoiceList = lazy(() => import('../../views/apps/invoice/list'))
const InvoiceEdit = lazy(() => import('../../views/apps/invoice/edit'))
const InvoicePrint = lazy(() => import('../../views/apps/invoice/print'))
const InvoicePreview = lazy(() => import('../../views/apps/invoice/preview'))

const EcommerceShop = lazy(() => import('../../views/apps/ecommerce/shop'))
const EcommerceDetail = lazy(() => import('../../views/apps/ecommerce/detail'))
const EcommerceWishlist = lazy(() => import('../../views/apps/ecommerce/wishlist'))
const EcommerceCheckout = lazy(() => import('../../views/apps/ecommerce/checkout'))

const UserList = lazy(() => import('../../views/apps/user/list'))
const UserView = lazy(() => import('../../views/apps/user/view'))

const RolesDesign = lazy(() => import('../../views/apps/roles-permissions/roles'))
const Permissions = lazy(() => import('../../views/apps/roles-permissions/permissions'))

// Roles
const Roles = lazy(() => import('../../views/apps/roles'))

// Users
const Users = lazy(() => import('../../views/apps/users'))

// Projects
const Projects = lazy(() => import('../../views/apps/projects'))

// Tours
const Tours = lazy(() => import('../../views/apps/tours'))
const ToursView = lazy(() => import('../../views/apps/tours/view'))
const ToursDetails = lazy(() => import('../../views/apps/tours/details'))
const ToursLocation = lazy(() => import('../../views/apps/tours/details/Location'))
const Subscriber = lazy(() => import('../../views/apps/tours/subscriber'))
const EditHotspot = lazy(() => import('../../views/apps/tours/hotspot/Edit'))

const AppRoutes = [
  {
    element: <Roles />,
    path: '/roles'
  },
  {
    element: <Users />,
    path: '/users'
  },
  {
    element: <Tours />,
    path: '/tours'
  },
  {
    element: <ToursView />,
    path: '/tours/:id'
  },
  {
    element: <ToursDetails />,
    path: '/tours/:id/details'
  },
  {
    element: <ToursLocation />,
    path: '/tours/:id/location'
  },
  {
    element: <Subscriber />,
    path: '/tours/:id/subscriber',
    meta: {
      appLayout: true,
      className: 'email-application'
    }
  },
  {
    element: <EditHotspot />,
    path: '/tours/:id/hotspot/:hotspot_id'
  },
  {
    path: '/logs',
    element: <Logs />,
    meta: {
      appLayout: true,
      className: 'todo-application'
    }
  },
  // {
  //   element: <Projects />,
  //   path: '/projects'
  // },
  // {
  //   element: <ProjectView />,
  //   path: '/projects/:id'
  // },
  // {
  //   element: <Email />,
  //   path: '/apps/email/:folder',
  //   meta: {
  //     appLayout: true,
  //     className: 'email-application'
  //   }
  // },
  // {
  //   element: <Email />,
  //   path: '/apps/email',
  //   meta: {
  //     appLayout: true,
  //     className: 'email-application'
  //   }
  // },
  // {
  //   element: <Email />,
  //   path: '/apps/email/label/:label',
  //   meta: {
  //     appLayout: true,
  //     className: 'email-application'
  //   }
  // },
  // {
  //   element: <Email />,
  //   path: '/apps/email/:filter'
  // },
  // {
  //   element: <Todo />,
  //   path: '/apps/todo',
  //   meta: {
  //     appLayout: true,
  //     className: 'todo-application'
  //   }
  // },
  // {
  //   element: <Todo />,
  //   path: '/apps/todo/:filter',
  //   meta: {
  //     appLayout: true,
  //     className: 'todo-application'
  //   }
  // },
  // {
  //   element: <Todo />,
  //   path: '/apps/todo/tag/:tag',
  //   meta: {
  //     appLayout: true,
  //     className: 'todo-application'
  //   }
  // },
  // {
  //   element: <Calendar />,
  //   path: '/apps/calendar'
  // },
  // {
  //   element: <Kanban />,
  //   path: '/apps/kanban',
  //   meta: {
  //     appLayout: true,
  //     className: 'kanban-application'
  //   }
  // },
  // {
  //   element: <InvoiceList />,
  //   path: '/apps/invoice/list'
  // },
  // {
  //   element: <InvoicePreview />,
  //   path: '/apps/invoice/preview/:id'
  // },
  // {
  //   path: '/apps/invoice/preview',
  //   element: <Navigate to='/apps/invoice/preview/4987' />
  // },
  // {
  //   element: <InvoiceEdit />,
  //   path: '/apps/invoice/edit/:id'
  // },
  // {
  //   path: '/apps/invoice/edit',
  //   element: <Navigate to='/apps/invoice/edit/4987' />
  // },
  // {
  //   element: <InvoiceAdd />,
  //   path: '/apps/invoice/add'
  // },
  // {
  //   path: '/apps/invoice/print',
  //   element: <InvoicePrint />,
  //   meta: {
  //     layout: 'blank'
  //   }
  // },
  // {
  //   element: <EcommerceShop />,
  //   path: '/apps/ecommerce/shop',
  //   meta: {
  //     className: 'ecommerce-application'
  //   }
  // },
  // {
  //   element: <EcommerceWishlist />,
  //   path: '/apps/ecommerce/wishlist',
  //   meta: {
  //     className: 'ecommerce-application'
  //   }
  // },
  // {
  //   path: '/apps/ecommerce/product-detail',
  //   element: <Navigate to='/apps/ecommerce/product-detail/apple-i-phone-11-64-gb-black-26' />,
  //   meta: {
  //     className: 'ecommerce-application'
  //   }
  // },
  // {
  //   path: '/apps/ecommerce/product-detail/:product',
  //   element: <EcommerceDetail />,
  //   meta: {
  //     className: 'ecommerce-application'
  //   }
  // },
  // {
  //   path: '/apps/ecommerce/checkout',
  //   element: <EcommerceCheckout />,
  //   meta: {
  //     className: 'ecommerce-application'
  //   }
  // },
  // {
  //   element: <UserList />,
  //   path: '/apps/user/list'
  // },
  // {
  //   path: '/apps/user/view',
  //   element: <Navigate to='/apps/user/view/1' />
  // },
  // {
  //   element: <UserView />,
  //   path: '/apps/user/view/:id'
  // },
  // {
  //   element: <RolesDesign />,
  //   path: '/apps/roles'
  // },
  // {
  //   element: <Permissions />,
  //   path: '/apps/permissions'
  // }
]

export default AppRoutes
