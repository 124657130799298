// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** API URL Imports
import { API_URL } from "@src/utility/Utils";

// ** Axios Imports
import axios from 'axios'

// get all project
export const getAllProjects = createAsyncThunk("project/getAllProjects", async () => {
  const res = await axios.get(`${API_URL}project/read.php`);
  return res.data.body;
});

// get all project
export const projectByUser = createAsyncThunk("project/projectByUser", async (uid) => {
  const res = await axios.get(`${API_URL}project/ProjectbyUser.php?uid=${uid}`);
  return [res.data];
});

// get project by pid
export const getProject = createAsyncThunk("project/getProject", async (pid) => {
  const res = await axios.get(`${API_URL}project/singleRead.php?pid=${pid}`);
  return res.data;
});

// add project
export const addProject = createAsyncThunk(
  "project/addProject",
  async (body, { dispatch }) => {
    const res = await axios.post(`${API_URL}project/create.php`, body);
    await dispatch(getAllProjects())
    return res.data.body;
  }
);

// update project
export const updateProject = createAsyncThunk(
  "users/updateProject",
  async (body, { dispatch }) => {
    const res = await axios.post(`${API_URL}project/update.php`, body);
    await dispatch(getAllProjects())
    return res.data.body;
  }
);

// remove project
export const removeProject = createAsyncThunk(
  "users/removeProject",
  async (pid, { dispatch }) => {
    const res = await axios.post(`${API_URL}project/delete.php`, { pid });
    await dispatch(getAllProjects())
    return res.data.body;
  }
);

// un-assign user from project
export const unassignProject = createAsyncThunk(
  "users/unassignProject",
  async (body, { dispatch }) => {
    const res = await axios.post(`${API_URL}assignproject/delete.php`, { asspid: body.asspid });
    await dispatch(getProjectUsers(body.pid))
    return res.data.body;
  }
);

// assign project
export const assignProject = createAsyncThunk(
  "project/assignProject",
  async (body, { dispatch }) => {
    const res = await axios.post(`${API_URL}assignproject/create.php`, body);
    await dispatch(getProjectUsers(body.pid))
    return res.data.body;
  }
);

// get project users by user_id
export const getProjectUsers = createAsyncThunk(
  "project/getProjectUsers",
  async (pid) => {
    const res = await axios.get(`${API_URL}assignproject/projectusers.php?pid=${pid}`);
    return res.data;
  }
);

// get projects along with users
export const getProjectsWithCreator = createAsyncThunk(
  "project/getProjectsWithCreator",
  async (pid) => {
    const res = await axios.get(`${API_URL}project/projectWithCreator.php?pid=${pid}`);
    return res.data;
  }
);

export const projectSlice = createSlice({
  name: 'projects',
  initialState: {
    loading: false,
    projects: [],
    userProjects: [],
    currentProject: null,
    editProject: null,
    postProjectRes: null,
    updateProjectRes: null,
    assignProjectRes: null,
    projectsWithCreator: null,
    projectUsers: null,
  },
  reducers: {
    setEditProject: (state, action) => {
      state.editProject = action.payload;
    },
  },
  extraReducers: {
    // get projects
    [getAllProjects.pending]: (state) => {
      state.loading = true;
    },
    [getAllProjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projects = payload;
    },
    [getAllProjects.rejected]: (state) => {
      state.loading = false;
    },

    // get projects by users
    [projectByUser.pending]: (state) => {
      state.loading = true;
    },
    [projectByUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userProjects = payload;
    },
    [projectByUser.rejected]: (state) => {
      state.loading = false;
    },

    // get projects
    [getProject.pending]: (state) => {
      state.loading = true;
    },
    [getProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.currentProject = payload;
    },
    [getProject.rejected]: (state) => {
      state.loading = false;
    },

    // get projects along with users
    [getProjectsWithCreator.pending]: (state) => {
      state.loading = true;
    },
    [getProjectsWithCreator.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projectsWithCreator = payload;
    },
    [getProjectsWithCreator.rejected]: (state) => {
      state.loading = false;
    },

    // post project
    [addProject.pending]: (state) => {
      state.loading = true;
    },
    [addProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postProjectRes = payload;
    },
    [addProject.rejected]: (state) => {
      state.loading = false;
    },

    // remove project
    [removeProject.pending]: (state) => {
      state.loading = true;
    },
    [removeProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postProjectRes = payload;
    },
    [removeProject.rejected]: (state) => {
      state.loading = false;
    },

    // remove project
    [unassignProject.pending]: (state) => {
      state.loading = true;
    },
    [unassignProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postProjectRes = payload;
    },
    [unassignProject.rejected]: (state) => {
      state.loading = false;
    },

    // update project
    [updateProject.pending]: (state) => {
      state.loading = true;
    },
    [updateProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateProjectRes = payload;
    },
    [updateProject.rejected]: (state) => {
      state.loading = false;
    },

    // assign project
    [assignProject.pending]: (state) => {
      state.loading = true;
    },
    [assignProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.assignProjectRes = payload;
    },
    [assignProject.rejected]: (state) => {
      state.loading = false;
    },

    // assign project
    [getProjectUsers.pending]: (state) => {
      state.loading = true;
    },
    [getProjectUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projectUsers = payload;
    },
    [getProjectUsers.rejected]: (state) => {
      state.loading = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setEditUser } = projectSlice.actions;
export default projectSlice.reducer
