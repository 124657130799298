// ** Icons Import
import { Home } from 'react-feather'

export default [
  {
    // id: 'dashboards',
    // title: 'Dashboards',
    // icon: <Home size={20} />,
    // badge: 'light-warning',
    // badgeText: '2',
    // children: [
    // {
    id: 'analyticsDash',
    title: 'Dashboard',
    icon: <Home size={12} />,
    navLink: '/dashboard'
    // }
    // ]
  }
]
