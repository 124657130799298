// ** Navigation imports
import Admin from './apps'
import User from './user'
import dashboards from './dashboards'
import { roles } from '../../auth/utils'


const AuthAdmin = (role = "Admin") => {
    // ** Merge & Export
    let routes = []
    if (role === roles.SUPER_ADMIN) {
        /** Super Admin Routes */
        return routes = [...dashboards, ...Admin]
    } else {
        /** Admin Routes */
        return routes = [...dashboards, ...User]
    }
}

export default AuthAdmin