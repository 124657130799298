import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "@src/utility/Utils";

const initialState = {
  value: 0,
  loading: false,
  roles: [],
  editRole: null,
  postRoleRes: "",
  updateRoleRes: "",
};

// get all roles
export const getAllRoles = createAsyncThunk("role/getAllRoles", async () => {
  const res = await axios.get(`${API_URL}Role/read.php`);
  return res.data.body;
});

// add role
export const addRole = createAsyncThunk("role/addRole", async (body, { dispatch }) => {
  const res = await axios.post(`${API_URL}Role/create.php`, body);
  await dispatch(getAllRoles())
  return res.data;
});

// remove role
export const removeRole = createAsyncThunk(
  "role/removeRole",
  async (roleid, { dispatch }) => {
    const res = await axios.post(`${API_URL}Role/delete.php`, { roleid });
    await dispatch(getAllRoles())
    return res.data;
  }
);

// update role
export const updateRole = createAsyncThunk(
  "role/updateRole",
  async (body, { dispatch }) => {
    const res = await axios.post(`${API_URL}Role/update.php`, body);
    await dispatch(getAllRoles())
    return res.data;
  }
);
export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setEditRole: (state, action) => {
      state.editRole = action.payload;
    },
  },
  extraReducers: {
    // get roles
    [getAllRoles.pending]: (state) => {
      state.loading = true;
    },
    [getAllRoles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.roles = payload;
    },
    [getAllRoles.rejected]: (state) => {
      state.loading = false;
    },

    // post role
    [addRole.pending]: (state) => {
      state.loading = true;
    },
    [addRole.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postRoleRes = payload;
    },
    [addRole.rejected]: (state) => {
      state.loading = false;
    },

    // remove role
    [removeRole.pending]: (state) => {
      state.loading = true;
    },
    [removeRole.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postRoleRes = payload;
    },
    [removeRole.rejected]: (state) => {
      state.loading = false;
    },

    // update role
    [updateRole.pending]: (state) => {
      state.loading = true;
    },
    [updateRole.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateRoleRes = payload;
    },
    [updateRole.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, setEditRole } =
  roleSlice.actions;
export default roleSlice.reducer;